// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-contact-js": () => import("/opt/build/repo/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-about-me-js": () => import("/opt/build/repo/src/templates/about-me.js" /* webpackChunkName: "component---src-templates-about-me-js" */),
  "component---src-templates-references-js": () => import("/opt/build/repo/src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-pictures-post-js": () => import("/opt/build/repo/src/templates/pictures-post.js" /* webpackChunkName: "component---src-templates-pictures-post-js" */),
  "component---src-templates-offer-js": () => import("/opt/build/repo/src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-cheatsheet-post-js": () => import("/opt/build/repo/src/templates/cheatsheet-post.js" /* webpackChunkName: "component---src-templates-cheatsheet-post-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-obrazky-index-js": () => import("/opt/build/repo/src/pages/obrazky/index.js" /* webpackChunkName: "component---src-pages-obrazky-index-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-tahaky-index-js": () => import("/opt/build/repo/src/pages/tahaky/index.js" /* webpackChunkName: "component---src-pages-tahaky-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

